import React from 'react';
import './MenuUser.css';

const MenuUser = ({ user, signOut }) => {
  console.log('Current user:', user); // Verifica el objeto `user`

  return (
    <>
      <div className="menu-user">
        <ul className="menu-links">

          {user?.username === 'eticayservicios' && (
            <>
              <li><a href='/upload'>Upload</a></li>
              <li><a href='/read'>Read Documents</a></li>
            </>
          )}
        </ul>
        
      </div>

    </>
  );
};

export default MenuUser;
