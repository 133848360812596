import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Header from './Header/Header';
import { fetchAuthSession } from '@aws-amplify/auth';

const ProtectedRoute = ({ element: Element, allowedGroups, user, signOut, ...rest }) => {
  const [userGroups, setUserGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUserGroups = async () => {
      try {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken.toString();
        const payload = JSON.parse(atob(idToken.split('.')[1]));
        setUserGroups(payload['cognito:groups'] || []);
      } catch (e) {
        console.error('Error fetching user groups:', e);
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      getUserGroups();
    }
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  const isAuthorized = user && (userGroups.some(group => allowedGroups.includes(group)));

  if (!isAuthorized) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Header user={user} signOut={signOut} />
      <Element user={user} {...rest} />
    </>
  );
};

export default ProtectedRoute;
