// src/hooks/useUserGroups.js
import { useState, useEffect } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth';

const useUserGroups = () => {
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const getUserGroups = async () => {
      try {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken.toString();
        const payload = JSON.parse(atob(idToken.split('.')[1]));
        setUserGroups(payload['cognito:groups'] || []);
      } catch (e) {
        console.error('Error fetching user groups:', e);
      }
    };

    getUserGroups();
  }, []);

  return userGroups;
};

export default useUserGroups;
